<template lang="html">

  <section class="form-contact">
    <Waypoint effect="fadeIn" class="container" id="contact">
      <p class="title">
        {{$t('home.banner-contact.title')}}
      </p>
      <p v-html="$t('home.banner-contact.subtitle')"></p>
    </Waypoint>
    <Waypoint effect="fadeIn">
      <Form ref="formObject" @submit="onSubmit" class="container-form">
        <div class="column three-width">
          <Field v-model="form.name" name="name" type="text" class="form-control" rules="required" :placeholder="$t('fields.fullname')" />
          <ErrorMessage name="name" />
        </div>
        <div class="column three-width">
          <Field v-model="form.email" name="email" type="text" class="form-control" rules="required|email" :placeholder="$t('fields.email')" />
          <ErrorMessage name="email" />
        </div>
        <div class="column three-width">
          <Field v-model="form.phone" name="phone" type="text" class="form-control phone" :rules="{ required: true, regex: /^[0-9]+$/ }" :placeholder="$t('fields.phone')" />
          <ErrorMessage name="phone" />
        </div>
        <div class="column">
          <textarea v-model="form.message" name="message" type="text" class="form-control message" rules="required" :placeholder="$t('fields.message')" />
          <ErrorMessage name="message" />
        </div>
        <div class="checkbox-styled">
          <Field id="aceptacion_legal" name="aceptacion_legal" type="checkbox" v-model="form.aceptacion_legal" :value="true" :unchecked-value="false" rules="required" />
          <label for="aceptacion_legal" v-html="$t('fields.policy')"> </label>
          <ErrorMessage name="aceptacion_legal" />
        </div>

        <div class="checkbox-styled">
          <Field id="newsletter" name="newsletter" type="checkbox" v-model="form.newsletter" :value="true" :unchecked-value="false" />
          <label for="newsletter" v-html="$t('fields.newsletter')"> </label>
          <ErrorMessage name="newsletter" />
        </div>
        <small v-html="$t('home.banner-contact.info-extra')"></small>
        <button>
          <span v-if="!!!sended && !!!loading">{{$t('fields.send')}}</span>
          <span v-if="sended && !!!loading">{{$t('fields.sended')}}</span>
          <span v-if="loading" class="loader"></span>
        </button>

      </Form>
    </Waypoint>
  </section>

</template>

<script lang="js">
  import { Waypoint } from 'vue-waypoint'
  import * as rules from '@vee-validate/rules';
  import { Form, Field, ErrorMessage } from 'vee-validate';
  export default {
    name: 'form-contact',
    props: [],
    components: {
      Form,
      Field,
      ErrorMessage,
      Waypoint
    },
    mounted() {

    },
    data() {
      return {
        loading: false,
        sended: false,
        form: {
          name: null,
          email: null,
          phone: null,
          message: null
        },
      }
    },
    methods: {
      onSubmit(values, actions) {
        let t = this;
        let data = new FormData();
        for (var key in this.form) {
          data.append(key, this.form[key]);
        }
        this.loading = true
        let promise = this.axios.post('https://dmb.doubledot.es/api/auto_contact/panama', data);
        promise.then(function (result) {
          t.result = JSON.stringify(result.data);
          if (result.data.status == true) {
            setTimeout(() => {
              t.loading = false
              t.sended = true
              t.form = {}
               actions.resetForm()
            }, 1000)
           
            setTimeout(() => {
              t.sended = false
            }, 4000)
          }
        })




      }
      // onSubmit() {
      //   let t = this;
      //   let data = new FormData();
      //   for (var key in this.form) {
      //     data.append(key, this.form[key]);
      //   }
      //   this.loading = true
      //   let promise = this.axios.post('https://dmb.doubledot.es/api/auto_contact/panama', data);
      //   promise.then(function (result) {
      //     t.result = JSON.stringify(result.data);
      //     if (result.data.status == true) {
      //       setTimeout(() => {
      //         t.loading = false
      //         t.sended = true
      //         t.form = {}
      //       }, 1000)
      //     }

      //   });
      // }
    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  .form-contact {}
</style>