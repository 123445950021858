<template lang="html">
  <section class="home">
    <section class="banner-video-intro">
      <img src="/img/bg-intro.webp" class="video-container">
      <video muted playsinline autoplay loop>
        <source src="/img/bg-intro.mp4" type="video/mp4">
      </video>
      <div class="content ">
        <div class="container">
          <Waypoint effect="fadeIn">
            <h1>{{$t('home.banner-intro.title')}}</h1>
            <h2>{{$t('home.banner-intro.subtitle')}}</h2>
          </Waypoint>
        </div>
      </div>
    </section>
    <section class="info-description">
      <Waypoint effect="fadeIn" class="info-description">
        <div class="container">
          <p class="p-medium">{{$t('home.banner-intro.description')}}</p>
        </div>
      </Waypoint>
    </section>
  </section>
  <Waypoint class="banner-video-beach" @change="playVideo" id="ubication">
    <img src="/img/video-beach.webp" class="video-container">

    <video muted id="video-beach" playsinline>
      <source src="/img/video-beach.mp4" type="video/mp4">
    </video>
    <div class="content">
      <Waypoint effect="fadeIn" class="container">
        <p class="title">{{$t('home.banner-white-beach.title')}}</p>
        <p class="p-medium">{{$t('home.banner-white-beach.subtitle')}}</p>
        <img :src="$t('home.banner-white-beach.img')" alt="Mapa playa blanca">
      </Waypoint>
    </div>
  </Waypoint>
  <section class="villas-component" id="villas">
    <div class="content">
      <Waypoint effect="fadeIn">
        <p class="title">{{$t('home.banner-villas.title')}}</p>
        <p v-html="$t('home.banner-villas.subtitle')"></p>
        <div class="links-villas">
          <button v-for="(villa ,key) in $tm('villas')" :key='key' @click="changeVilla(key)" :active="villaSelected == key  ? true : false">{{villa.title}}</button>
        </div>
      </Waypoint>
    </div>
    <div class="image">
      <Waypoint effect="fadeInLeft">
        <img :src="$t('home.banner-villas.img')" alt="">
      </Waypoint>
    </div>
  </section>
  <Waypoint effect="fadeIn" class="content-info-villas">
    <div class="content">
      <div>
        <p class="title-medium">{{$t('home.banner-villas.villa')}} {{$t('villas.'+this.villaSelected+'.title')}}</p>
        <p>{{$t('villas.'+this.villaSelected+'.description')}}</p>
      </div>
      <div class="description">
        <p><strong>{{$t('home.banner-villas.parcela')}}: </strong> {{$t('villas.'+this.villaSelected+'.parcela')}} </p>
        <p><strong>{{$t('home.banner-villas.villa')}}: <span v-if="$t('villas.'+this.villaSelected+'.villa-b')!= ''">A: </span></strong>{{$t('villas.'+this.villaSelected+'.villa')}}
          <span v-if="$t('villas.'+this.villaSelected+'.villa-b')!= ''">| <strong>{{$t('home.banner-villas.villa')}} B: </strong>{{$t('villas.'+this.villaSelected+'.villa-b')}}</span>
        </p>
        <div class="link-container">
          <a :href="'/img/'+this.villaSelected+'/documentacion-'+this.$i18n.locale+'.pdf'" class="link-dowload" target="_blank">{{$t('home.banner-villas.download-info')}}</a>
          <button :href="'/img/'+this.villaSelected+'/documentacion-'+this.$i18n.locale+'.pdf'" class="link-video" @click="activepopup = true">{{$t('home.banner-villas.video')}} {{$t('home.banner-villas.villa')}} {{$t('villas.'+this.villaSelected+'.title')}}</button>
        </div>
      </div>
    </div>
    <div class="container-slider">
      <agile :options="myOptionsVillas" ref="carouselVillas" class="carrousel-home" v-if="$t('villas.'+this.villaSelected+'.nPhotos') > 1 || $tm('villas.'+this.villaSelected+'.video') == true">
        <div v-for="num in parseFloat($tm('villas.'+this.villaSelected+'.nPhotos'))" :key="num" class="div-image agile__slide">
          <img :src="'/img/'+this.villaSelected+'/villa-'+num+'.jpg'" alt="">
        </div>
        <div v-if="$tm('villas.'+this.villaSelected+'.video') == true" class="div-image -video" @click="activepopup = true">
          <span class="mask-video"></span>
          <img :src="'/img/'+this.villaSelected+'/iframe-video.png'" alt="iframe video">
        </div>
      </agile>
      <template v-else>
        <div class="div-image">
          <img :src="'/img/'+this.villaSelected+'/villa-1.jpg'" alt="">
        </div>
      </template>
    </div>
  </Waypoint>
  <Waypoint v-if="activepopup && villaSelected" effect="fadeIn" class="popup" :activepopup="activepopup">
    <div class="container-video">
      <button @click="activepopup = false">
        <img src="/img/close.svg" alt="video">
      </button>
      <video controls autoplay loop preload="yes" class="video-normal">
        <source :src="'/videos/video_'+this.villaSelected+'.mp4'" type="video/mp4">
      </video>
    </div>
  </Waypoint>
  <Waypoint effect="fadeIn" class="container-services" @change="onChange">
    <div class="info-head">
      <div class="container" id="architecture">
        <p class="title">{{$t('home.banner-architecture.title')}}</p>
        <p v-html="$t('home.banner-architecture.subtitle')"></p>

        <div class="grid-services">
          <div class="item" v-for="(service ,key) in $tm('home.banner-architecture.services')" :key='key' @mouseenter="icons.architecture[''+key+''].playState = true" @mouseleave="icons.architecture[''+key+''].playState = false">
            <div class="container-animation">
              <Vue3Lottie v-if="icons.architecture[''+key+''].playState && icons.architecture.active" :animationData="icons.architecture[''+key+''].name" height="auto" width="100%" :loop="1" />
              <img v-else :src="'/img/icon-'+key+'.svg'" :alt="service.replace('<br>', ' ')">
            </div>
            <span v-html="service"></span>
          </div>

        </div>
      </div>
    </div>
    <div class="services" id="services">
      <div class="container">
        <p class="title">{{$t('home.banner-services.title')}}</p>
        <p v-html="$t('home.banner-services.extraInfo')"></p>
        <div class="grid-services services">
          <div class="item" v-for="(service ,key) in $tm('home.banner-services.services')" :key='key' @mouseenter="icons[''+key+''].playState = true" @mouseleave="icons[''+key+''].playState = false">
            <div class="container-animation">
              <Vue3Lottie v-if="icons[''+key+''].playState" :animationData="icons[''+key+''].name" height="auto" width="100%" />
              <img v-else :src="'/img/icon-'+key+'.svg'" :alt="service.replace('<br>', ' ')">
            </div>
            <span v-html="service"></span>
          </div>

        </div>
        <p class="title-medium">{{$t('home.banner-services.subtitle')}}</p>
        <p v-html="$t('home.banner-services.description')"></p>
        <div class="grid-services services-back-house">
          <div class="item" v-for="(service ,key) in $tm('home.banner-services.services-back-house')" :key='key' @mouseenter="icons[''+key+''].playState = true" @mouseleave="icons[''+key+''].playState = false">
            <div class="container-animation">
              <Vue3Lottie v-if="icons[''+key+''].playState" :animationData="icons[''+key+''].name" height="auto" width="100%" />
              <img v-else :src="'/img/icon-'+key+'.svg'" :alt="service.replace('<br>', ' ')">
            </div>
            <span v-html="service"></span>
          </div>
        </div>
      </div>
    </div>
  </Waypoint>
  <agile :options="myOptionsEnviroment" ref="carousel" id="environment">
    <section class="banner-full-enviroment" data="enviroment">
      <Waypoint effect="fadeIn" class="container">
        <p class="title-medium">{{$t('home.banner-enviroment.slide1')}}</p>
      </Waypoint>
    </section>
    <section class="banner-full-enviroment" data="temperature">
      <Waypoint effect="fadeIn" class="container">
        <p class="title-medium">{{$t('home.banner-enviroment.slide2')}}</p>
      </Waypoint>
    </section>
    <section class="banner-full-enviroment" data="costa">
      <Waypoint effect="fadeIn" class="container">
        <p class="title-medium">{{$t('home.banner-enviroment.slide3')}}</p>
      </Waypoint>
    </section>
  </agile>

  <section class="info-description" id="experiences">
    <Waypoint effect="fadeIn" class="info-description">
      <div class="container">
        <p class="title">{{$t('home.banner-experiencies.title')}}</p>
        <p>{{$t('home.banner-experiencies.subtitle')}}</p>
      </div>
    </Waypoint>
  </section>
  <Waypoint effect="fadeIn" class="banner-experiences">
    <agile :options="myOptionsExperiences" ref="carousel" class="carrousel-experiencias ">
      <div v-for="(experience ,key) in $tm('experiences')" :key='key'>
        <img :src="`/img/experiences/img-`+ key+`.jpg`" :alt="experience">
        <p>{{experience}}</p>
      </div>
    </agile>
  </Waypoint>
  <section effect="fadeIn" class="banner-subfooter"></section>
  <section class="footer">
    <formContact></formContact>
  </section>
</template>
<script lang="js">
  import { Vue3Lottie } from 'vue3-lottie'
  import 'vue3-lottie/dist/style.css'
  import { Waypoint } from 'vue-waypoint'
  import { VueAgile } from "vue-agile";
  import formContact from '@/components/formContact';
  import 'vue3-lottie/dist/style.css'
  import ecoHome from '@/lotties/eco-home.json'
  import viviendasEficientes from '@/lotties/viviendas-eficientes.json'
  import aprovechamemiento from '@/lotties/aprovechamemiento.json'
  import interiores from '@/lotties/interiores.json'
  import comunidadVigilada from '@/lotties/comunidad-vigilada.json'
  import trafico from '@/lotties/trafico.json'
  import piscina from '@/lotties/piscina.json'
  import jardines from '@/lotties/jardines.json'
  import countryClub from '@/lotties/country-club.json'
  import alquilerTuristico from '@/lotties/alquiler-turistico.json'
  import parques from '@/lotties/parques.json'
  import zonasDeportivas from '@/lotties/zonas-deportivas.json'
  import beachClub from '@/lotties/beach-club.json'
  import limpieza from '@/lotties/limpieza.json'
  import ropaCama from '@/lotties/ropa-de-cama.json'
  import temperatura from '@/lotties/temperatura.json'
  import compraDomicilio from '@/lotties/compra-domicilio.json'
  import mantenimientoJardin from '@/lotties/mantenimiento-jardin.json'

  export default {
    name: 'home',
    props: [],
    components: {
      Waypoint,
      agile: VueAgile,
      formContact
    },
    mounted() {

    },

    data() {
      return {
        villaSelected: 'caoba',
        activepopup: false,
        icons: {
          architecture: {
            'eco-home': {
              name: ecoHome,
              playState: false,
            },
            'viviendas-eficientes': {
              name: viviendasEficientes,
              playState: false,
            },
            'aprovechamemiento': {
              name: aprovechamemiento,
              playState: false,
            },
            'interiores': {
              name: interiores,
              playState: false,
            },
            active: false
          },

          'comunidad-vigilada': {
            name: comunidadVigilada,
            playState: false,
          },
          'trafico': {
            name: trafico,
            playState: false,
          },
          'piscina': {
            name: piscina,
            playState: false,
          },
          'jardines': {
            name: jardines,
            playState: false,
          },
          'country-club': {
            name: countryClub,
            playState: false,
          },
          'alquiler-turistico': {
            name: alquilerTuristico,
            playState: false,
          },
          'parques': {
            name: parques,
            playState: false,
          },
          'zonas-deportivas': {
            name: zonasDeportivas,
            playState: false,
          },
          'beach-club': {
            name: beachClub,
            playState: false,
          },
          'limpieza': {
            name: limpieza,
            playState: false,
          },
          'ropa-de-cama': {
            name: ropaCama,
            playState: false,
          },
          'temperatura': {
            name: temperatura,
            playState: false,
          },
          'compra-domicilio': {
            name: compraDomicilio,
            playState: false,
          },
          'mantenimiento-jardin': {
            name: mantenimientoJardin,
            playState: false,
          }
        },

        myOptionsVillas: {
          responsive: [{
            breakpoint: 0,
            settings: {
              fade: false,
              navButtons: true,
              slidesToShow:1,
              dots: false,
              infinite: false,
              centerMode: true
            },

          },
          {
            breakpoint: 900,
            settings: {
              fade: false,
              navButtons: true,
              slidesToShow:2,
              dots: false,
              infinite: false,
              centerMode: true
            }
          }],
        },
        myOptionsExperiences: {
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                fade: false,
                navButtons: true,
                slidesToShow: 5,
                dots: false,
                //infinite: false,
                centerMode: true
              },
            },
            {
              breakpoint: 900,
              settings: {
                fade: false,
                navButtons: true,
                slidesToShow: 3,
                dots: false,
                //infinite: false,
                centerMode: true
              },
            },
            {
              breakpoint: 0,
              settings: {
                fade: false,
                navButtons: true,
                slidesToShow: 2,
                dots: false,
                //infinite: false,
                centerMode: true
              },
            }
          ],
        },
        myOptionsEnviroment: {
          responsive: [{
            breakpoint: 0,
            settings: {
              fade: true,
              navButtons: false,
              slidesToShow: 1,
              dots: true,
              infinite: true,
              autoplay: true,
              pauseOnHover: true,
              autoplaySpeed: 4000,
              centerMode: true
            },
          }],
        },
      }
    },
    methods: {

      playVideo(waypointState) {
        const video = document.getElementById('video-beach');
        if (waypointState.going == 'IN') {
          video.play()
        } else {
          video.currentTime = 0
        }

      },
      changeVilla(villa) {
        this.villaSelected = villa,
          this.$refs.carouselVillas.goTo(0)
      },
      onChange(waypointState) {
        if (waypointState.going == 'IN') {
          this.icons.architecture = {
            'eco-home': {
              name: ecoHome,
              playState: true,
            },
            'viviendas-eficientes': {
              name: viviendasEficientes,
              playState: true,
            },
            'aprovechamemiento': {
              name: aprovechamemiento,
              playState: true,
            },
            'interiores': {
              name: interiores,
              playState: true,
            },
            active: true
          }

        }
      }
    },
    computed: {

    }
  }


</script>